import styled from "styled-components";
import { Container } from "../../styles/UI";

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

export const Planos = styled.section`
  padding: 40px 0 80px;
  background: ${(p) => p.theme.bgGray};
  text-align: center;

  p.escolha {
    font-size: 24px;
    line-height: 32px;
    color: ${(p) => p.theme.textDarkerGray};
    margin-bottom: 24px;
  }

  .cards {
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
    }
  }


  p.proposta {
    margin-top: 72px;
    font-size: 19px;
    font-weight: 600;
    color: ${(p) => p.theme.textDarkGray};
  }
`;

export const Faq = styled.section`
  padding: 80px 0;
  background: ${(p) => p.theme.bgGray};

  h5.title {
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.primary};
    margin-bottom: 32px;
  }

  p.duvidas {
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    color: ${(p) => p.theme.textDarkerGray};
    margin-top: 40px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 80px 0;

    p.duvidas {
      margin-top: 86px;

      div {
        margin-left: 16px;
      }
    }
  }
`;

export const Produtos = styled.section`
  padding: 104px 0;

  ${Container} > h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    background: ${(p) => p.theme.degradeMisto};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    ${Container} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 32px;
      padding-top: 124px;
      padding-bottom: 124px;

      .column-1 {
        grid-column: 1;
      }
      .row-1 {
        grid-row: 1;
      }
      .column-2 {
        grid-column: 2;
      }
      .row-2 {
        grid-row: 2;
      }
    }

    ${Container} > h3 {
      font-size: 35px;
    }
  }
`;
