import { motion } from "framer-motion";
import styled from "styled-components";

import checkIcon from "../../images/check_circle.svg";
import closeIcon from "../../images/close-circle-line.svg";

export const Card = styled(motion.div)`
  background-color: var(--color-white);
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 0px 20px;
  margin-top: 44px;
  max-width: 570px;
  width: 100%;

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    margin-left: 32px;

    &:first-of-type {
      margin-left: 0;
    }

    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  color: ${(p) => p.theme.branco};
  background: ${(p) => (p.$premium ? p.theme.laranja : p.$business ? p.theme.text : p.theme.primary)};
  border-radius: 0px 20px;
  text-align: center;
  position: relative;
  padding: 16px;

  h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 42px;
  }
`;

export const Recomendado = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${(p) => p.theme.branco};
  display: flex;
  align-items: center;
  border-radius: 0px 16px;
  padding: 6px 12px;
  transform: translateY(-50%);

  &&& p {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: ${(p) => p.theme.text};
    margin: 0 0 0 8px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    border-radius: 0px 16px;
    transform: translateY(0%);
  }
`;

export const Features = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .features {
    padding: 36px 24px;

    ul {
      list-style-type: none;
      margin: 0;

      li {
        padding-left: 36px;
        padding-bottom: 20px;
        background-image: url(${checkIcon});
        background-position: 0 0;
        background-repeat: no-repeat;
        margin-left: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: ${(p) => p.theme.text};
        text-align: left;
      }
    }
  }

  .obs {
    padding: 16px 36px;
    margin-top: auto;
    margin-bottom: 0;

    p {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: ${(p) => p.theme.textGray};
      text-align: left;
      margin-bottom: 8px;
    }

    .mt {
      margin-top: 44px;
    }
  }

  .indicado {
    padding: 36px 24px;
    border-top: 1px solid #e3e3e3;
    color: ${(p) => p.theme.textGray};
    text-align: left;

    p {
      color: ${(p) => p.theme.textGray};
      font-style: normal;
      font-weight: 350;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  ul.close li {
    background-image: url(${closeIcon});
    color: ${(p) => p.theme.textDarkGray};
  }
`;

export const Price = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #e3e3e3;
  text-align: center;
  justify-content: center;

  p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    line-height: 32px;
    color: ${(p) => p.theme.text};
  }

  & > div {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.text};
    text-align: right;
    font-weight: 600;

    span {
      display: block;
      color: ${(p) => p.theme.laranja};
    }
  }

  &&& div + span {
    font-weight: 600;
    font-size: 50px;
    margin-left: 8px;
    color: ${(p) => p.theme.laranja};
  }

  &&& p:last-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.textDarkerGray};
    margin-bottom: 0;
    margin-top: auto;
  }
`;

export const ObsPrice = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: ${(p) => p.theme.textGray};
  text-align: left;
  margin: 8px 32px 32px;
`;

export const CTA = styled.div`
  padding: 0 24px 8px;

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: ${(p) => (p.$taxdashs ? "0 56px 16px" : "0 24px 8px")};
    display: grid;
    grid-template-columns: ${(p) => (p.$taxdashs ? "1fr" : "1fr 1fr")};
    grid-template-rows: ${(p) => (p.$taxdashs ? "1fr 1fr 1fr" : "1fr")};
    grid-column-gap: 24px;

    .span-2 {
      grid-column: span 2;
    }
  }
`;
