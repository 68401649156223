import { graphql } from "gatsby";
import React from "react";
import Planos from "../pagesFiles/planos";

export default function planos({ data }) {
  return <Planos questions={data.allFaqJson.nodes[0].planos} pricesTaxSheets={data.allPricesJson.nodes[0].taxsheets} pricesTaxDashs={data.allPricesJson.nodes[1].taxdashs}/>;
}

export const query = graphql`
  {
    allFaqJson {
      nodes {
        planos {
          body
          id
          question
        }
      }
    }
    allPricesJson {
      nodes {
        taxdashs {
          padrao
          media
          alta
        }
        taxsheets {
          basic
          premium
        }
      }
    }
  }
`;
