import React from "react";

import { AnimateSharedLayout, motion } from "framer-motion";

import taxsheetsLogo from "../../images/taxsheets/Logo TaxSheets.png";
import taxdashsLogo from "../../images/taxdashs/Logo TaxDashs.png";

import * as S from "./style";

export default function ToggleButton({
  variant,
  selection = "taxsheets",
  handleChange,
}) {
  return (
    <S.Toggle $variant={variant}>
      <AnimateSharedLayout>
        <button title="TaxSheets" onClick={() => handleChange("taxsheets")}>
          <img src={taxsheetsLogo} alt="Logo TaxSheets" />
          {selection === "taxsheets" && (
            <motion.div
              className="active"
              layoutId="active"
              animate={{ backgroundColor: "#FFFFFF" }}
              transition={spring}
            />
          )}
        </button>
        <button title="TaxDashs" onClick={() => handleChange("taxdashs")}>
          <img src={taxdashsLogo} alt="Logo TaxDashs" />
          {selection === "taxdashs" && (
            <motion.div
              className="active"
              layoutId="active"
              animate={{ backgroundColor: "#FFFFFF" }}
              transition={spring}
            />
          )}
        </button>
      </AnimateSharedLayout>
    </S.Toggle>
  );
}

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
};
