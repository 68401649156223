import styled from "styled-components";

export const Toggle = styled.div`
  padding: 4px;
  border-radius: 20px 0px;
  box-sizing: border-box;
  width: auto;
  display: inline-block;
  background: ${(p) =>
    p.$variant === 1 ? "rgba(199, 209, 219, 0.25)" : "#F7F9FA"};
  border: ${(p) =>
    p.$variant === 1
      ? "2px solid rgba(199, 209, 219, 0.25)"
      : "2px solid rgba(171, 179, 186, 0.25);"};
  height: 64px;
  display: inline-flex;

  button {
    position: relative;
    display: flex;
    height: 100%;
    border: none;
    background: none;
    border-radius: 16px 0px;
    padding: 12px 24px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 150ms ease-in-out;

    /* &:hover {
      img {
        transform: translateY(-2px);
      }
    } */

    img {
      transition: all 150ms ease-in-out;
      width: 110px;
      z-index: 10;
    }

    div.active {
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      border-radius: 16px 0px;
      position: absolute;
      background-color: #ffffff;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
