import React, { useState } from "react";

import { Container } from "../../styles/UI";
import Layout from "../../components/Layout";
import ProductCard from "../../components/ProductCard";
import QuestionWrapper from "../../components/QuestionWrapper";
import StyledLink from "../../DesignSystem/StyledLink";
import PlanoCard from "../../components/PlanoCard";
import ApresentacaoHome from "../home/apresentacao";

import taxcelBgLogo from "../../images/home/logo-negative.svg";

import * as S from "./style";
import ToggleButton from "../../components/ToggleButton.js";
import { AnimatePresence } from "framer-motion";
import Seo from "../../components/Seo";

export default function Planos({ questions, pricesTaxDashs, pricesTaxSheets }) {
  const [produto, setProduto] = useState("taxsheets");

  const changeProduto = (selection) => {
    setProduto(selection);
  };

  return (
    <Layout>
      <Seo title="Planos" />
      <S.Hero>
        <Container>
          <div className="cta">
            <h1>
              Planos sob medida para um setor tributário mais proativo e
              estratégico
            </h1>
            <p>Teste 7 dias grátis</p>
          </div>
          <div className="illustration">
            <img src={taxcelBgLogo} alt="" className="bg-logo" />
          </div>
        </Container>
      </S.Hero>
      <S.Planos>
        <Container>
          <p className="escolha">Escolha o produto:</p>
          <ToggleButton
            variant={2}
            selection={produto}
            handleChange={changeProduto}
          ></ToggleButton>
          {/* <ToggleButton variant={1} selection={produto} handleChange={changeProduto}></ToggleButton> */}
          <div className="cards">
            <AnimatePresence exitBeforeEnter>
              {produto === "taxsheets" ? (
                <>
                  <PlanoCard
                    key="basic"
                    titulo="Basic"
                    preco={pricesTaxSheets.basic}
                  >
                    <div className="features">
                      <ul>
                        <li>
                          Converte SPED de arquivos TXT para planilha do Excel
                          (EFD ICMS-IPI, EFD Contribuições, ECD, ECF, NFe, NFCe,
                          CFe e CTe)
                        </li>
                        <li>
                          Empilhar em um único Excel arquivos de diversos
                          períodos/anos e CNPJs (Sem limite)
                        </li>
                        <li>
                          Converte lotes de arquivos XML (NFe, NFCe, CFe e CTe)
                          em planilhas do Excel - <b>Layout Simplificado</b>
                        </li>
                        <li>
                          Sistema integrado de suporte via ticket, e-mail e chat
                        </li>
                      </ul>
                      <ul className="close">
                        <li>
                          Exporta o arquivo TXT no layout do SPED para ser
                          validado no PVA (EFD ICMS-IPI, EFD Contribuições, ECD,
                          ECF)
                        </li>
                        <li>Exporta múltiplos arquivos ao mesmo tempo</li>
                        <li>Recálculo automático de campos relacionados</li>
                        <li>
                          Configuração Proxy (para empresas com políticas de
                          restrição de rede)
                        </li>
                        <li>Importação PERD/COMP (.dec)</li>
                        <li>Importação e exportação DCTF (.dec) e GIA SP</li>
                        <li>Suporte em tempo real com acesso remoto</li>
                        <li>Validações (ECF / ICMS / Contrib)</li>
                        <li>
                          Importação de obrigações XML (DCTF Web, Reinf e
                          e-Social)
                        </li>
                        <li>
                          Importação e exportação de obrigações em PDF
                          (PER/DCOMP Web e DARF)
                        </li>
                        <li>
                          Importação e exportação de CATs (CAT 42, CAT 207 e CAT
                          83)
                        </li>
                        <li>
                          Gera registros (R11,R12,R13,R15 e R21) de
                          ressarcimento de IPI com base no EFD ICMS-IPI
                        </li>
                        <li>
                          Cross Check (EFD ICMS x GIA / DCTF x Contrib / ICMS x
                          ECF / Contrib x ICMS / NFe x ICMS)
                        </li>
                        <li>
                          <b>Mais:</b> Implementação de novas funcionalidades
                          disponíveis na ferramenta (roadmap)
                        </li>
                      </ul>
                    </div>
                    <div className="obs">
                      <p>
                        A versão TaxSheets Basic é exclusiva apenas para
                        Consultorias, Auditorias, Escritórios de Advocacia e
                        Escritórios Contábeis.
                      </p>
                    </div>
                  </PlanoCard>
                  <PlanoCard
                    key="premium"
                    titulo="Premium"
                    preco={pricesTaxSheets.premium}
                  >
                    <div className="features">
                      <ul>
                        <li>
                          Converte SPED de arquivos TXT para planilha do Excel
                          (EFD ICMS-IPI, EFD Contribuições, ECD, ECF, NFe, NFCe,
                          CFe e CTe)
                        </li>
                        <li>
                          Empilhar em um único Excel arquivos de diversos
                          períodos/anos e CNPJs (Sem limite)
                        </li>
                        <li>
                          Converte lotes de arquivos XML (NFe, NFCe, CFe e CTe)
                          em planilhas do Excel - <b>Layout Simplificado</b>
                        </li>
                        <li>
                          Sistema integrado de suporte via ticket, e-mail e chat
                        </li>
                        <li>
                          Exporta o arquivo TXT no layout do SPED para ser
                          validado no PVA (EFD ICMS-IPI, EFD Contribuições, ECD,
                          ECF)
                        </li>
                        <li>Exporta múltiplos arquivos ao mesmo tempo</li>
                        <li>Recálculo automático de campos relacionados</li>
                        <li>
                          Configuração Proxy (para empresas com políticas de
                          restrição de rede)
                        </li>
                        <li>Importação PERD/COMP (.dec)</li>
                        <li>Importação e exportação DCTF (.dec) e GIA SP</li>
                        <li>Suporte em tempo real com acesso remoto</li>
                      </ul>
                      <ul className="close">
                        <li>Validações (ECF / ICMS / Contrib)</li>
                        <li>
                          Importação de obrigações XML (DCTF Web, Reinf e
                          e-Social)
                        </li>
                        <li>
                          Importação e exportação de obrigações em PDF
                          (PER/DCOMP Web e DARF)
                        </li>
                        <li>
                          Importação e exportação de CATs (CAT 42, CAT 207 e CAT
                          83)
                        </li>
                        <li>
                          Gera registros (R11,R12,R13,R15 e R21) de
                          ressarcimento de IPI com base no EFD ICMS-IPI
                        </li>
                        <li>
                          Cross Check (EFD ICMS x GIA / DCTF x Contrib / ICMS x
                          ECF / Contrib x ICMS / NFe x ICMS)
                        </li>
                        <li>
                          <b>Mais:</b> Implementação de novas funcionalidades
                          disponíveis na ferramenta (roadmap)
                        </li>
                      </ul>
                    </div>
                  </PlanoCard>
                  <PlanoCard
                    key="business"
                    titulo="Business"
                    preco={"0"}
                    recomendado
                  >
                    <div className="features">
                      <ul>
                        <li>
                          Converte SPED de arquivos TXT para planilha do Excel
                          (EFD ICMS-IPI, EFD Contribuições, ECD, ECF, NFe, NFCe,
                          CFe e CTe)
                        </li>
                        <li>
                          Empilhar em um único Excel arquivos de diversos
                          períodos/anos e CNPJs (Sem limite)
                        </li>
                        <li>
                          Converte lotes de arquivos XML (NFe, NFCe, CFe e CTe)
                          em planilhas do Excel - <b>Layout Completo</b>
                        </li>
                        <li>
                          Sistema integrado de suporte via ticket, e-mail e chat
                        </li>
                        <li>
                          Exporta o arquivo TXT no layout do SPED para ser
                          validado no PVA (EFD ICMS-IPI, EFD Contribuições, ECD,
                          ECF)
                        </li>
                        <li>Exporta múltiplos arquivos ao mesmo tempo</li>
                        <li>Recálculo automático de campos relacionados</li>
                        <li>
                          Configuração Proxy (para empresas com políticas de
                          restrição de rede)
                        </li>
                        <li>Importação PERD/COMP (.dec)</li>
                        <li>Importação e exportação DCTF (.dec) e GIA SP</li>
                        <li>Suporte em tempo real com acesso remoto</li>
                        <li>Validações (ECF / ICMS / Contrib)</li>
                        <li>
                          Importação de obrigações XML (DCTF Web, Reinf e
                          e-Social)
                        </li>
                        <li>
                          Exportação de obrigações XML (Reinf e e-Social)
                        </li>
                        <li>
                          Importação e exportação de obrigações em PDF
                          (PER/DCOMP Web e DARF)
                        </li>
                        <li>
                          Importação e exportação de CATs (CAT 42, CAT 207 e CAT
                          83)
                        </li>
                        <li>
                          Gera registros (R11,R12,R13,R15 e R21) de
                          ressarcimento de IPI com base no EFD ICMS-IPI
                        </li>
                        <li>
                          Cross Check (EFD ICMS x GIA / DCTF x Contrib / ICMS x
                          ECF / Contrib x ICMS / NFe x ICMS)
                        </li>
                        <li>
                          <b>Mais:</b> Implementação de novas funcionalidades
                          disponíveis na ferramenta (roadmap)
                        </li>
                      </ul>
                    </div>
                  </PlanoCard>
                </>
              ) : (
                <>
                  <PlanoCard
                    key="padrao"
                    taxdashs
                    titulo="Capacidade Padrão"
                    preco={pricesTaxDashs.padrao}
                  >
                    <div className="features">
                      <ul>
                        <li>10 GB de dados de capacidade</li>
                        <li>
                          Converte arquivos TXT do SPED em dashboards do Power
                          BI
                        </li>
                        <li>
                          Mais de 30 templates de dashboards já construídos e
                          prontos para análise
                        </li>
                        <li>Dashboards Editáveis e Customizáveis</li>
                        <li>Até 3 usuários</li>
                      </ul>
                    </div>
                    <div className="indicado">
                      <p>Indicado para:</p>
                      <ul>
                        <li>
                          Área Fiscal de Corporações com até 5 Estabelecimentos
                          Operacionais
                        </li>
                        <li>Consultorias e Auditorias de pequeno porte</li>
                        <li>Escritórios Contábeis de até 15 Clientes</li>
                      </ul>
                    </div>
                  </PlanoCard>
                  <PlanoCard
                    key="media"
                    taxdashs
                    titulo="Capacidade Média"
                    preco={pricesTaxDashs.media}
                  >
                    <div className="features">
                      <ul>
                        <li>30 GB de dados de capacidade</li>
                        <li>
                          Converte arquivos TXT do SPED em dashboards do Power
                          BI
                        </li>
                        <li>
                          Mais de 30 templates de dashboards já construídos e
                          prontos para análise
                        </li>
                        <li>Dashboards Editáveis e Customizáveis</li>
                        <li>Até 5 usuários</li>
                      </ul>
                    </div>
                    <div className="indicado">
                      <p>Indicado para:</p>
                      <ul>
                        <li>
                          Área Fiscal de Corporações com até 30 Estabelecimentos
                          Operacionais
                        </li>
                        <li>Consultorias e Auditorias de pequeno porte</li>
                        <li>Escritórios Contábeis de até 50 Clientes</li>
                      </ul>
                    </div>
                  </PlanoCard>
                  <PlanoCard
                    key="alta"
                    taxdashs
                    titulo="Capacidade Alta"
                    preco={pricesTaxDashs.alta}
                  >
                    <div className="features">
                      <ul>
                        <li>60 GB de dados de capacidade</li>
                        <li>
                          Converte arquivos TXT do SPED em dashboards do Power
                          BI
                        </li>
                        <li>
                          Mais de 30 templates de dashboards já construídos e
                          prontos para análise
                        </li>
                        <li>Dashboards Editáveis e Customizáveis</li>
                        <li>Até 10 usuários</li>
                      </ul>
                    </div>
                    <div className="indicado">
                      <p>Indicado para:</p>
                      <ul>
                        <li>
                          Área Fiscal de Corporações com até 50 Estabelecimentos
                          Operacionais
                        </li>
                        <li>Consultorias e Auditorias de pequeno porte</li>
                        <li>Escritórios Contábeis de até 100 Clientes</li>
                      </ul>
                    </div>
                  </PlanoCard>
                </>
              )}
            </AnimatePresence>
          </div>
          {produto === "taxdashs" ? (
            <p className="proposta">
              Para capacidades acima de 60 GB de dados,{" "}
              <StyledLink to="/contato#form-contato">
                solicite uma proposta personalizada.
              </StyledLink>
            </p>
          ) : null}
        </Container>
      </S.Planos>
      <S.Faq>
        <Container>
          <h5 className="title">Dúvidas frequentes</h5>
          <QuestionWrapper questions={questions} />
          <p className="duvidas">
            Mais dúvidas?{" "}
            <StyledLink
              external
              to="https://taxceladdins.zendesk.com/hc/pt-br"
              arrow
              color="laranja"
            >
              Acesse nosso Portal de Ajuda
            </StyledLink>
          </p>
        </Container>
      </S.Faq>
      <ApresentacaoHome white />
      <S.Produtos>
        <Container $clip>
          <h3 className="column-1 row-1">
            Conheça nossa solução para Compliance
          </h3>
          <ProductCard className="column-1 row-2" product="taxsheets" />
          <h3 className="column-2 row-1">
            Conheça nossa solução para Analytics e BI
          </h3>
          <ProductCard className="column-2 row-2" product="taxdashs" />
        </Container>
      </S.Produtos>
    </Layout>
  );
}
